import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import burger from './menu.svg';
import close from './close.svg';
import './styles.scss';

const MobileMenu = ({ location }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="menu menu--mobile">
      {open ? (
        <button type="button" className="menu__close" onClick={() => setOpen(false)}>
          <img src={close} alt="close menu icon" />
        </button>
      ) : (
        <button type="button" className="menu__burger" onClick={() => setOpen(true)}>
          <img src={burger} alt="burger menu icon" />
        </button>
      )}
      <nav className={`menu__navigation ${open && 'menu__navigation--open'}`}>
        <ul>
          <li>
            <Link to={`/information-and-contact/${location.search}`} onClick={() => setOpen(false)}>
              Information and Contact
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

MobileMenu.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default MobileMenu;
