import React from 'react';
import PropTypes from 'prop-types';

import './typography.scss';

function Text({ component, children, style }) {
  const className = `heading heading__${component}`;

  if ([`h1`, `h2`, `h3`, `h4`, `h5`].includes(component)) {
    return React.createElement(component, { style, className }, children);
  }

  return (
    <p className="body-font body-font__p" style={style}>
      {children}
    </p>
  );
}

Text.defaultProps = {
  component: null,
  style: null,
};

Text.propTypes = {
  component: PropTypes.oneOf([`p`, `h1`, `h2`, `h3`, `h4`, `h5`]),
  style: PropTypes.shape({}),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

export default Text;
export { Text };
