import React from 'react';
import PropTypes from 'prop-types';

import { Text } from './text';

import './typography.scss';

function Typography({
  component, children, style, align, className,
}) {
  return (
    <div
      className={className ?? ``}
      style={{
        ...(align && { textAlign: align }),
      }}
    >
      <Text component={component} style={style}>
        {children}
      </Text>
    </div>
  );
}

Typography.defaultProps = {
  component: `p`,
  style: null,
  align: null,
  className: null,
};

Typography.propTypes = {
  className: PropTypes.string,
  component: PropTypes.oneOf([`p`, `h1`, `h2`, `h3`, `h5`]),
  align: PropTypes.oneOf([`left`, `right`, `center`]),
  style: PropTypes.shape({}),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

export default Typography;
export { Typography };
