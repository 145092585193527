import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import logo from '@images/tcg-logo.svg';
import Menu from './Menu';

import './header.scss';

function Header({ location }) {
  // const [ctaLink, setCtaLink] = useState(process.env.WFH_LP_LINK);
  //
  // useEffect(() => {
  //   setCtaLink(`${process.env.WFH_LP_LINK}/${location.search}`);
  // }, []);

  return (
    <header className="header__wrapper">
      <div className="container header">
        <div className="header__container">
          <Menu location={location} />

          <Link to={`/${location.search}`}>
            <img className="header__logo" src={logo} alt="The Claims Guys" />
          </Link>

          {/* <a className="header__cta-link" href={ctaLink}> */}
          {/*  Start my working from home claim */}
          {/* </a> */}
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default Header;
export { Header };
