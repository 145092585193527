import React from 'react';
import { HLocation } from '@reach/router';

import './cta.scss';

interface CTAProps {
  location: HLocation;
  text: string;
  link: string;
  disabled?: boolean;
  customClass?: string;
  type?: 'secondary' | 'header' | 'tablet' | 'desktop-xl' | 'left';
}

function CTA(props: CTAProps) {
  const {
    location,
    text,
    link,
    disabled,
    customClass = '',
    type = '',
  } = props;

  return (
    <button
      type="button"
      onClick={() => {
        window.location.href = `${link}/${location.search}`;
      }}
      className={`cta ${type ? `cta--${type}` : ''} ${customClass} ${disabled ? 'disabled' : ''}`}
    >
      <span className="cta__inner">{text}</span>
    </button>
  );
}

export default CTA;
export { CTA };
