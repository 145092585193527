import React from 'react';
import PropTypes from 'prop-types';
import { Footer, Header } from '@components';

import '@styles/main.scss';
import './layout.scss';

function Layout({
  children, location,
}) {
  return (
    <div>
      <Header location={location} />
      <main>{children}</main>
      <Footer location={location} />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default Layout;
export { Layout };
