import React from 'react';
import { Link as Scroll } from 'react-scroll';
import { Link } from 'gatsby';

import Copyright from '@ropto/ui-copyright';
import logo from '@images/tcg-logo.svg';

import './footer.scss';
import PropTypes from 'prop-types';

function Footer({ location }) {
  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <div className="footer__back-to-top">
          <Scroll to="header" smooth duration={500}>Back to top</Scroll>
        </div>

        <div className="footer__header">
          <img src={logo} alt="TCG logo" />
          <div className="footer__policy-wrapper">
            <Link to={`/terms-of-use/${location.search}`}>Terms of Use</Link>
            <Link to={`/privacy-policy/${location.search}`}>Privacy Policy</Link>
          </div>
        </div>
        <div className="footer__copyright">
          <Copyright productName="TheClaimsGuys. All rights reserved." />
        </div>

        <div className="footer__disclaimer">
          The Claims Guys Limited is registered in England company no: 06821134.
          Registered address: Hamilton House, Church Street, Altrincham, Cheshire,
          WA14 4DR.
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default Footer;
export { Footer };
