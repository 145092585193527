/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import favicon from '@images/favicon.png';

interface SEOProps {
  title?: string;
  description?: string;
  lang?: string;
}

function SEO(props: SEOProps) {
  const { description = '', lang = 'en', title = 'The Claims Guys' } = props;

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `);

  const metaDescription = description || site.siteMetadata.description;

  const helmetProps = {
    htmlAttributes: { lang },
    title,
    link: [{ rel: `shortcut icon`, type: `image/x-icon`, href: `${favicon}` }],
    meta: [
      {
        name: `description`,
        content: metaDescription,
      },
      {
        property: `og:title`,
        content: title,
      },
      {
        property: `og:description`,
        content: metaDescription,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        name: `twitter:card`,
        content: `summary`,
      },
      {
        name: `twitter:creator`,
        content: site.siteMetadata.author,
      },
      {
        name: `twitter:title`,
        content: title,
      },
      {
        name: `twitter:description`,
        content: metaDescription,
      },
    ],
  };

  return <Helmet {...helmetProps} />;
}

export default SEO;
export { SEO };
